import React, {useState} from 'react';

import DashboardA from './components/DashboardA';


function App() {

    

    return (
        <div>
            <div>
                <DashboardA

                />
            </div>
        </div>
    );
}

export default App;
