import netflixLogo from "../images/netflixLogo.png";
import disneyLogo from "../images/disneyLogo.png";
import fubotvLogo from "../images/fubotvLogo.png";
import youtubeLogo from "../images/youtubeLogo.png";
import hboLogo from "../images/hboLogo.png";
import huluLogo from "../images/huluLogo.png";
import f1tvLogo from "../images/f1Logo.png";
import primeLogo from "../images/primeLogo.png";
import peacockLogo from "../images/peacockLogo.png";
import maxLogo from "../images/maxLogo.png";

const Services = [
  {
    name: "Netflix",
    url: "https://netflix.com",
    logo: netflixLogo,
  },
  {
    name: "Hulu",
    url: "https://www.hulu.com/",
    logo: huluLogo,
  },
  {
    name: "Youtube",
    url: "https://www.youtube.com",
    logo: youtubeLogo,
  },
  {
    name: "Max",
    url: "https://play.max.com/",
    logo: maxLogo,
  },
  {
    name: "F1",
    url: "https://www.f1tv.com",
    logo: f1tvLogo,
  },
  {
    name: "Prime",
    url: "https://smile.amazon.com/Amazon-Video/b/?ie=UTF8&node=2858778011&ref_=nav_cs_prime_video",
    logo: primeLogo,
  },
  {
    name: "Peacock",
    url: "https://www.peacocktv.com/",
    logo: peacockLogo,
  },
];

export default Services;
